import { Button } from '@material-ui/core';
import React from 'react'
import { useDispatch } from 'react-redux'
import { auth, provider } from './firebase';
import './Login.css';
import {login} from './features/appSlice'

function Login() {
    const dispatch = useDispatch();
    const signin = () =>{
        auth.signInWithPopup(provider)
            .then(result =>{
                dispatch(login({
                    username: result.user.displayName,
                    profilePic: result.user.photoURL,
                    id: result.user.uid
                }))
            }).catch(error=> alert(error.message))
    }
    return (
        <div className="login">
            <div className='login__container'>
            <h2>Snapachat Clone built using React and Redux</h2>
            <Button variant='outlined' onClick={signin}>Sign in</Button>
            

            </div>

        </div>
    )
}

export default Login
