import React, { useEffect } from 'react';
import WebcamCapture from "./webcampCapture"
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import Preview from './Preview';
import Chats from './Chats'
import ChatView from './ChatView'
import { useSelector } from 'react-redux';
import { selectUser } from './features/appSlice';
import { useDispatch} from 'react-redux';
import Login from './Login'
import { auth } from './firebase';
import {login, logout} from './features/appSlice'


function App() {
  const user = useSelector(selectUser)
  const dispatch = useDispatch();


useEffect(()=>{
  auth.onAuthStateChanged((authUser)=>{
    if (authUser) {
      dispatch(login({
        username: authUser.username,
        profilePic: authUser.photoURL,
        id: authUser.uid
      }))
    } else{
      dispatch(logout())
    }
  })
})

  return (
    <div className="App">

      <Router>
      {(!user) ?
        <Login />
      : (

      <div className="app_body">
        <Switch>

        <Route exact path="/chats">
            <Chats />
          </Route>

          <Route path="/chats/view">
            <ChatView />
          </Route>

          <Route path="/preview">
            <Preview />
          </Route>

          <Route exact path="/">
          <WebcamCapture />
          </Route>
        </Switch>
      </div>
      )}
    </Router>

    </div>
  );
}

export default App;
