import React, { useEffect } from 'react';
import {useSelector} from 'react-redux';
import { useHistory } from 'react-router-dom';
import { resetCameraImage, selectCameraImage } from './features/cameraSlice';
import './Preview.css';
import CloseIcon from "@material-ui/icons/Close";
import { useDispatch} from 'react-redux';
import SendIcon from "@material-ui/icons/Send";
import {v4 as uuid} from 'uuid';
import {storage, db} from "./firebase";
import firebase from 'firebase';
import { selectUser } from './features/appSlice';



function Preview() {
    const cameraImage = useSelector(selectCameraImage);
    const history = useHistory();
    const dispatch = useDispatch();
    const user = useSelector(selectUser);


    useEffect(()=> {
        if (!cameraImage){
            history.replace('/')
        }
    }, [cameraImage, history]);

    const closePreview = () => {
        dispatch(resetCameraImage());
    }

    const sendPost = () => {
        const id = uuid();
        const uploadTask = storage
            .ref(`posts/${id}`)
            .putString(cameraImage, "data_url");

        uploadTask.on("state_changeed", null, (error) => {
            console.log(error)
        }, () => {
            storage
                .ref('posts')
                .child(id)
                .getDownloadURL()
                .then((url) => {
                    db.collection('posts')
                    .add({
                        imageUrl: url,
                        username: 'Jameela',
                        read: false,
                        
                        timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
                    });
                    history.replace('/chats')
                })
        }
        );
    }


    return (
        <div className="preview">
            <CloseIcon onClick={closePreview} className="preview__close" />
            <div className="preview__footer">
                <h2>Send Now</h2>
                <SendIcon onClick={sendPost} className="preview__sendIcond" fontSize="small" />
            </div>
            <img src={cameraImage} alt="None"></img>
        </div>
    )
}

export default Preview
