import firebase from 'firebase';
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCxSDSrZKOr-WfkZDaRSBYh2hlJd1Tz_-o",
    authDomain: "pro-fitness-662fa.firebaseapp.com",
    projectId: "pro-fitness-662fa",
    storageBucket: "pro-fitness-662fa.appspot.com",
    messagingSenderId: "866397257062",
    appId: "1:866397257062:web:1572c497e8a54d5fb79ca7",
    measurementId: "G-NHXVZD02F1"
  };

  const firebaseApp = firebase.initializeApp(firebaseConfig);
  const db = firebaseApp.firestore();
  const auth = firebase.auth();
  const storage = firebase.storage();
  const provider = new firebase.auth.GoogleAuthProvider();

  export { db, auth, storage, provider };