import { useCallback, useRef, useState } from "react";
import Webcam from "react-webcam";
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { selectCameraImage, setCameraImage } from "./features/cameraSlice";
import { useDispatch} from 'react-redux';
import { useHistory } from "react-router-dom";
import './WebcamCapture.css';



const videoConstraints = {
    width:  400,
    height: 600,
    facingMode: "user"
};

function WebcamCapture() {
    const webcamRef = useRef(null);
    const dispatch = useDispatch();
    const history = useHistory();

    const capture = useCallback(() => {
        const imageSrc = webcamRef.current.getScreenshot();
        dispatch(setCameraImage(imageSrc));
        history.push("/preview")

    }, [webcamRef]);


    
    return <div className='webcamCapture'>
        <Webcam 
            audio = {false}
            height = {videoConstraints.height}
            ref = {webcamRef}
            screenshotFormat = "image/jpeg"
            width = {videoConstraints.width}
            videoConstraints = {videoConstraints}
        />
        <RadioButtonUncheckedIcon 
            className = "webcamCapture__button"
            onClick={capture}
        />

    </div>
}

export default WebcamCapture;